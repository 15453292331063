import GLightbox from 'glightbox';
import Glide from '@glidejs/glide'
import { Form } from '@netivo/base-scripts';

let lightbox = new GLightbox({
    width: '150%',
});

function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

// window.initMap = initMap;

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
            document.querySelector('.js-hamburger').classList.toggle("active");
        }
    });
}

const elem = document.querySelector('input[name="date_purchase"]');
if(elem){
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    let todayDate = dd  + '/' + mm + '/' + yyyy;
    const datepicker = new Datepicker(elem, {
        minDate: '28/06/2021',
        maxDate: todayDate,
        format: 'dd/mm/yyyy',
        autohide: true
    });
}

// let header = document.querySelector('.js-header');
// let sticky = header.offsetTop;
//
// window.onscroll = function(){stickyStart()};
//
// function stickyStart() {
//     if(window.pageYOffset > sticky){
//         header.classList.add('header--scrolled');
//     } else {
//         header.classList.remove('header--scrolled');
//     }
// }

window.addEventListener('DOMContentLoaded', () => {

    let contact_form = document.querySelector('.js-contact-form');
    if (contact_form !== null) {
        new Form(contact_form, {
            action: '/wp-json/netivo/v1/contact',
            data: el => {
                return new Promise((resolve, reject) => {
                    let data = {
                        name: '',
                        surname: '',
                        email: '',
                        phone: '',
                        message: ''
                    };

                    let form_first_name = el.querySelector('[name="name"]'),
                        form_surname = el.querySelector('[name="surname"]'),
                        form_email = el.querySelector('[name="email"]'),
                        form_phone = el.querySelector('[name="phone"]'),
                        form_message = el.querySelector('[name="message"]');

                    if (form_first_name !== null) data.name = form_first_name.value;
                    if (form_surname !== null) data.surname = form_surname.value;
                    if (form_email !== null) data.email = form_email.value;
                    if (form_phone !== null) data.phone = form_phone.value;
                    if (form_message !== null) data.message = form_message.value;

                    resolve( data );
                });
            },
            success: (el, response) => {
                if (response.status === 'success') {
                    let status = document.querySelector('[data-element="status"]');
                    let message = status.querySelector('[data-element="success-message"]');
                    status.querySelector('[data-element="error-message"]').style.display = 'none';
                    status.classList.add('shown');
                    setTimeout( () => {
                        message.classList.add('shown');
                        el.querySelector('[name="name"]').value = '';
                        el.querySelector('[name="surname"]').value = '';
                        el.querySelector('[name="email"]').value = '';
                        el.querySelector('[name="message"]').value = '';
                        el.querySelector('[name="phone"]').value = '';
                    }, 500);

                    let close = message.querySelector('[data-element="close-button"]');
                    let close_top = message.querySelector('[data-element="close-button-top"]');
                    if (close !== null && close_top !== null) {
                        close.addEventListener('click', (e) => {
                            e.preventDefault();
                            message.classList.remove('shown');
                            setTimeout( () => {
                                status.classList.remove('shown');
                            }, 500);
                        });
                        close_top.addEventListener('click', (e) => {
                            e.preventDefault();
                            message.classList.remove('shown');
                            setTimeout( () => {
                                status.classList.remove('shown');
                            }, 500);
                        });
                    }
                }
            },
            error: (el, response) => {
                let status = document.querySelector('[data-element="status"]');
                let message = status.querySelector('[data-element="error-message"]');
                status.querySelector('[data-element="success-message"]').style.display = 'none';
                status.classList.add('shown')
                setTimeout( () => {
                    message.classList.add('shown');
                }, 500);

                let close = message.querySelector('[data-element="close-button"]');
                let close_top = message.querySelector('[data-element="close-button-top"]');
                if (close !== null && close_top !== null) {
                    close.addEventListener('click', (e) => {
                        e.preventDefault();

                        message.classList.remove('shown');
                        setTimeout( () => {
                            status.classList.remove('shown');
                        }, 500);
                    });
                    close_top.addEventListener('click', (e) => {
                        e.preventDefault();

                        message.classList.remove('shown');
                        setTimeout( () => {
                            status.classList.remove('shown');
                        }, 500);
                    });
                }
            }
        });
    }


    let q_crls = null;
    let quote_carousel = document.querySelector('.js-quote-carousel');
    if (quote_carousel !== null) {
        let prev_button = quote_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = quote_carousel.parentNode.querySelector('[data-element="button-next"]');

        q_crls = new Glide(quote_carousel, {
            perView: 1,
            gap: '0',
            type: 'carousel',
            bound: true,
            breakpoints: {
                991: {
                    perView: 1,
                },
            }
        }).mount();
    }

    let r_crls = null;
    let references_carousel = document.querySelector('.js-references-carousel');
    if (references_carousel !== null) {
        let prev_button = references_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = references_carousel.parentNode.querySelector('[data-element="button-next"]');

        r_crls = new Glide(references_carousel, {
            perView: 1,
            gap: '0',
            type: 'carousel',
            bound: true,
            autoplay: 6000,
            breakpoints: {
                991: {
                    perView: 1,
                },
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            r_crls.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            r_crls.go('>');
        });
    }

    let rd_crls = null;
    let references_double_carousel = document.querySelector('.js-references-double-carousel');
    if (references_double_carousel !== null) {
        let prev_button = references_double_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = references_double_carousel.parentNode.querySelector('[data-element="button-next"]');

        rd_crls = new Glide(references_double_carousel, {
            perView: 2,
            gap: '0',
            type: 'carousel',
            bound: true,
            autoplay: 4000,
            breakpoints: {
                991: {
                    perView: 1,
                },
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            rd_crls.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            rd_crls.go('>');
        });
    }

    let a_crls = null;
    let articles_carousel = document.querySelector('.js-article-carousel');
    if (articles_carousel !== null) {
        let prev_button = articles_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = articles_carousel.parentNode.querySelector('[data-element="button-next"]');

        a_crls = new Glide(articles_carousel, {
            perView: 4,
            gap: '20',
            type: 'carousel',
            bound: true,
            autoplay: 4000,
            breakpoints: {
                540: {
                    perView: 1,
                },
                800: {
                    perView: 2,
                },
                1000: {
                    perView: 3,
                },
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            a_crls.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            a_crls.go('>');
        });
    }

    let e_crls = null;
    let slider_carousel = document.querySelector('.js-slider-carousel');
    if (slider_carousel !== null) {
        let prev_button = slider_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = slider_carousel.parentNode.querySelector('[data-element="button-next"]');

        e_crls = new Glide(slider_carousel, {
            perView: 1,
            gap: '0',
            type: 'carousel',
            bound: true,
            autoplay: 6000,
            breakpoints: {
                991: {
                    perView: 1,
                },
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            e_crls.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            e_crls.go('>');
        });
    }

    let c_crls = null;
    let companies_carousel = document.querySelector('.js-companies-carousel');
    if (companies_carousel !== null) {
        let prev_button = companies_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = companies_carousel.parentNode.querySelector('[data-element="button-next"]');

        c_crls = new Glide(companies_carousel, {
            perView: 8,
            gap: '0',
            type: 'carousel',
            bound: true,
            autoplay: 4000,
            breakpoints: {
                380: {
                    perView: 1,
                },
                540: {
                    perView: 2,
                },
                840: {
                    perView: 4,
                },
                1200: {
                    perView: 6,
                },
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            c_crls.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            c_crls.go('>');
        });
    }

    setTimeout(() => {
        let interactive_elements = document.querySelectorAll('.interactive-element');

        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 100;
        if (document.body.clientWidth <= 600) {
            window_top = document.body.scrollTop - 50;
        }
        let window_bottom = (window_top + window_height);

        for (const inter_element of interactive_elements) {
            let element_height = inter_element.clientHeight;
            let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
            let element_bottom = (element_top + element_height) - 20;

            if ((element_bottom >= window_top) && element_top <= window_bottom) {
                inter_element.classList.add('visible');
            }
        }
    }, 100);
});

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 100;
    if (document.body.clientWidth <= 600) {
        window_top = document.body.scrollTop - 50;
    }
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }
});

